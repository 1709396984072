window.addEventListener('DOMContentLoaded', () => {
    const countdownElement = document.getElementById('countdown');
    const daysElement = countdownElement.querySelector('#days');
    const hoursElement = countdownElement.querySelector('#hours');
    const minutesElement = countdownElement.querySelector('#minutes');
    const secondsElement = countdownElement.querySelector('#seconds');
    const countdownContentHeader = document.querySelector('#countdown-header');
    const previewText = document.querySelector('#fade-text');

    const endDate = new Date('2024-10-29 14:29:00');
    const now = new Date();
    let secondsLeft = (endDate.getTime() - new Date().getTime()) / 1000;

    if (now >= endDate) {
        const countdownContent = document.querySelector('.countdown-content');
        const countdownContentFinal = document.querySelector('.countdown-content-final');

        countdownContentHeader.textContent = 'The release has finally happened';
        countdownContentHeader.classList.remove('spacer-top');
        countdownContent.classList.add('d-none');
        previewText.classList.add('d-none');
        countdownContentFinal.classList.add('d-flex');
        countdownContentFinal.classList.add('flex-column');
        return;
    }

    function animateValue(element, start, end, duration) {
        const range = end - start;
        const stepTime = Math.abs(Math.floor(duration / range));
        let current = start;
        let increment = end > start ? 1 : -1;

        const timer = setInterval(function () {
            current += increment;
            if (increment > 0 && current > end || increment < 0 && current < end) {
                current = end;
                clearInterval(timer);
            }
            element.textContent = formatTime(current);
        }, stepTime);
    }

    function formatTime(value) {
        return value < 10 ? '0' + value : value;
    }

    let initialDays = Math.floor(secondsLeft / 86400);
    let initialHours = Math.floor((secondsLeft % 86400) / 3600);
    let initialMinutes = Math.floor((secondsLeft % 3600) / 60);
    let initialSeconds = Math.floor(secondsLeft % 60);

    animateValue(daysElement, 0, initialDays, 2000);
    animateValue(hoursElement, 0, initialHours, 2000);
    animateValue(minutesElement, 0, initialMinutes, 2000);
    animateValue(secondsElement, 0, initialSeconds, 2000);

    function updateCountdown() {
        secondsLeft -= 1;

        const days = Math.floor(secondsLeft / 86400);
        daysElement.textContent = formatTime(days);

        const hours = Math.floor((secondsLeft % 86400) / 3600);
        hoursElement.textContent = formatTime(hours);

        const minutes = Math.floor((secondsLeft % 3600) / 60);
        minutesElement.textContent = formatTime(minutes);

        const seconds = Math.floor(secondsLeft % 60);
        secondsElement.textContent = formatTime(seconds);

        if (secondsLeft <= 0) {
            const countdownContent = document.querySelector('.countdown-content');
            const countdownContentFinal = document.querySelector('.countdown-content-final');

            countdownContentHeader.textContent = 'The release has finally happened';
            countdownContent.classList.add('d-none');
            countdownContentFinal.classList.add('d-flex');
        }
    }

    setInterval(updateCountdown, 1000);
});
