import { createApp } from 'vue'
import App from './App.vue'
import './coundown.js';

createApp(App).mount('#app')

const phrases = [
    'Hello 👋',
    'We\'re already in the process of developing 👨‍💻',
    'It will be a revolutionary time tracking app ⚡️',
    'Clockyco - is convenient, practical, and affordable! 🚀',
    'In the meantime, you can check out our social media 😉',
    'So you don\'t miss news about us 😉',
    'See you soon, dear future customer 🙌'
]

let currentPhraseIndex = 0;
const textElement = document.getElementById('fade-text');

function fadeOutIn() {
    textElement.style.opacity = 0;
    setTimeout(() => {
        currentPhraseIndex = (currentPhraseIndex + 1) % phrases.length;
        textElement.textContent = phrases[currentPhraseIndex];
        textElement.style.opacity = 1;
    }, 1000);
}

setInterval(fadeOutIn, 3000);

window.onload = () => {
    textElement.style.opacity = 1;
};