<template>
    <div class="container">
        <div>
            <img style="height: 45px" class="logo" src="@/../public/logo-white.svg" alt="Clockyco logo">
        </div>
        <div class="text-container">
            <div class="text" id="fade-text">Hello 👋</div>
            <h2 id="countdown-header" class="countdown-header spacer-top spacer-bottom">Release will be in</h2>
            <section class="countdown" id="countdown">
                <div class="countdown-content">
                    <div class="countdown-content-item">
                        <h2 id="days">00</h2>
                        <p>days</p>
                    </div>

                    <div class="countdown-content-item">
                        <h2 id="hours">00</h2>
                        <p>hours</p>
                    </div>

                    <div class="countdown-content-item">
                        <h2 id="minutes">00</h2>
                        <p>minutes</p>
                    </div>

                    <div class="countdown-content-item">
                        <h2 id="seconds">00</h2>
                        <p>seconds</p>
                    </div>
                </div>

                <div class="countdown-content-final">
                    <p class="countdown-content-final-text">Oops, we may not have changed the site in time 🫢</p>
                    <p class="countdown-content-final-text">Here's the link <a title="Clockyco application" href="https://app.clockyco.app/">Explore Clockyco</a> 😎🔥</p>
                </div>
            </section>
        </div>
        <ul class="social-media">
            <li><a href="https://www.linkedin.com/company/clockyco/" target="_blank">Linkedin</a></li>
            <li><a href="https://x.com/clockyco" target="_blank">Twitter</a></li>
            <li><a href="https://cases.media/en/clockyco/" target="_blank">Cases</a></li>
        </ul>
    </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">
@import "styles/main";
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
</style>
